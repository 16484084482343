import React from 'react';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const IconPlus = dynamic(() => import('#app/UI/atoms/icons/plus'));
const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const CardLearnings = ({ title, description, srcImg }) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerImg}>
        <img className={styles.image} src={srcImg} alt={title} />
        {/* src="/niubiz/pci.png" */}
      </div>
      <div className={styles.details}>
        <div className={styles.text}>
          <Typography className={styles.title} tag="p">
            {title}
          </Typography>
          <Typography className={styles.description} tag="p">
            {description}
          </Typography>
        </div>
        <div className={styles.button}>
          <div className={styles.plus}>
            <IconPlus />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLearnings;
